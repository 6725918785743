// Flatly 5.0.2
// Bootswatch

// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Oxygen:ital,wght@0,300;0,400;0,700&display=swap" !default;
$brand-font-path: "https://fonts.googleapis.com/css2?family=League+Gothic:ital,wght@0,400&display=swap";
$gov-font-path: "https://fonts.cdnfonts.com/css/rawline";

@if $web-font-path {
  @import url($web-font-path);
}

@if $brand-font-path {
  @import url($brand-font-path);
}

@if $gov-font-path {
  @import url($gov-font-path);
}


// Navbar

.bg-primary {
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link:focus {
    color: $success !important;
  }
}

.navbar.bg-light {
  background-color: #fff !important;
  border-bottom: 1px solid $gray-300;
}

// Navs

.nav-tabs {
  .nav-link.active,
  .nav-link.active:focus,
  .nav-link.active:hover,
  .nav-item.open .nav-link,
  .nav-item.open .nav-link:focus,
  .nav-item.open .nav-link:hover {
    color: $primary;
  }
}

.nav-tabs {
  .nav-link:hover {
    background-color: $gray-200;
  }
}

.nav-tabs .nav-link {
  border: 1px solid $gray-300;
  border-bottom: 1px solid $gray-200;
}

.pagination {
  align-items: center;
  a:hover {
    text-decoration: none;
  }
}

// Indicators

.badge {
  &.bg-light {
    color: $dark;
  }
}

.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(180deg, mix($body-bg, $value, 15%), $value) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }

  &-light {
    &,
    a,
    .alert-link {
      color: $body-color;
    }
  }
}

// Containers

.modal,
.toast {
  .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
    // background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
  }
}
