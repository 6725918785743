@import "src/scss/_variables";
@import "~bootstrap/scss/bootstrap";
@import "src/scss/_bootswatch";

// Telas pequenas
.sm-hide {
  display: inline-block;
}

// fonte menor em telas pequenas
@media screen and (max-width: 768px) {
  html {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 576px) {
  html {
    font-size: 0.83rem;
  }

  // ocultar se a tela for pequena
  .sm-hide {
    display: none;
  }
}

body {
  padding-top: 0px;
  background-color: #f0f5ff;
}

main {
  min-height: 78.5vh;
  height: auto !important;
  height: 100vh;
  margin-bottom: 10vh;
  padding-bottom: 0;
}

.conteudo-barra-brasil {
  width: 100% !important;
  max-width: 1180px !important;
}

@media (min-width: 1200px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    width: 100% !important;
    max-width: 1200px !important;
    padding-right: 15px;
  }
  .px-lg-12 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
}

ol.jsonpipe, div.jsonpipe {
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
  line-height: 1rem;
}

ol.breadcrumb {
  padding: 0;
  font-size: 0.875rem;
  margin-bottom: 2rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  margin-top: -.49em;
  font-size: 2.2em;
  content: "\203A";
}

li.breadcrumb-item {
  color: #95a5a6;
}

li.breadcrumb-item .link {
  color: #95a5a6 !important;
}

// spinner
.placeholder {
  opacity: 0.9 !important;
  background-color: #dee2e6;
}

.spinner-border {
  z-index: 12000;
}

// tabs
.nav-pills .nav-link.active {
  background-color: #117964;
  border-color: #343a40;
}

// tables
.table {
  box-shadow: 6px 11px 41px -28px #a99de7;
}

@media (max-width: 768px) {
  thead {
    display: none;
  }
  table,
  tbody,
  tr,
  td {
    display: block;
  }

  table tr {
    display: block;
  }

  td[data-label]:before {
    content: attr(data-label) "\003A \0020";
    font-weight: bold;
  }

  td[data-label=""]:before {
    content: "";
  }

  .table > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
  }
}

@media (min-width: 768px) {
  table tbody tr:last-child {
    border-bottom: 0 none #fff;
  }
}

table thead tr {
  // margin: 62px !important;
  border-bottom: 2px solid #b4bcc2;
}

table thead tr th{
  padding-top: 0 !important;
}

.card .table,
.card-body .table {
  box-shadow: none;
}

.min-width-md-1 {
  min-width: 10vw;
}

.min-width-md-2 {
  min-width: 15vw;
}

th.sortable {
  cursor: pointer;
}

th.sortable:after {
  content: "";
  width: 10px;
  height: 10px;
  margin-right: -10px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGqSURBVEhL7Zc7TsNAEIb9iONHDHYexBKOUnMGSqpcgYIaUdMh0XIDGgrOQAE9JYdANNCAQmGiUIQE8492LEXGwo7tjUDik0b27s5kMuP1zlgpS7/fP+10Otc8lI/jONuWZX3ouh67rrvH03Jpt9tXmqbFqqrGnuc9YUoXK5JASncR4Sc5JCHnvu8f87IU1G63e09pTZySNJvNKdZcoVIz2DyHSVqXpdFoxK1W65LVasVBROO0w0TgeIH1Hdath+FweJEVZSK0hl19B1VVWFTEtu0BolhkOVsWcmya5ojNqtHr9W5/inJZEO0zTExh+YeQ+9KvkyI7bQOs9Iwmk0mMy6sYfSfPKRlXIfP3Nb6uldz0oqJ48/nc4GFhkOIx3/7zC0ENLXQMkg5K3AtMajkGB4ZhFDrwsfH22aY6KODnedGiGtVX2hgHnV+U5YxEShEnwjA8SvdHJPwspbQrhBoEwUM6zeiBqTHbFCr5rHoMxrPZ7IAc8VjBvYLoT3D7JmYkgTTfJNGiAj1iSn7dxYbawis0LftZUeofIsXv6Jt0NGHTKIrOeLogivIF9cWeeqG1hhkAAAAASUVORK5CYII=");
  background-size: 10px 10px;
  display: inline-block;
}

th.sortable.text-right:after {
  content: none;
}

th.sortable.text-right:before {
  content: "";
  width: 10px;
  height: 10px;
  margin-left: -10px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGqSURBVEhL7Zc7TsNAEIb9iONHDHYexBKOUnMGSqpcgYIaUdMh0XIDGgrOQAE9JYdANNCAQmGiUIQE8492LEXGwo7tjUDik0b27s5kMuP1zlgpS7/fP+10Otc8lI/jONuWZX3ouh67rrvH03Jpt9tXmqbFqqrGnuc9YUoXK5JASncR4Sc5JCHnvu8f87IU1G63e09pTZySNJvNKdZcoVIz2DyHSVqXpdFoxK1W65LVasVBROO0w0TgeIH1Hdath+FweJEVZSK0hl19B1VVWFTEtu0BolhkOVsWcmya5ojNqtHr9W5/inJZEO0zTExh+YeQ+9KvkyI7bQOs9Iwmk0mMy6sYfSfPKRlXIfP3Nb6uldz0oqJ48/nc4GFhkOIx3/7zC0ENLXQMkg5K3AtMajkGB4ZhFDrwsfH22aY6KODnedGiGtVX2hgHnV+U5YxEShEnwjA8SvdHJPwspbQrhBoEwUM6zeiBqTHbFCr5rHoMxrPZ7IAc8VjBvYLoT3D7JmYkgTTfJNGiAj1iSn7dxYbawis0LftZUeofIsXv6Jt0NGHTKIrOeLogivIF9cWeeqG1hhkAAAAASUVORK5CYII=");
  background-size: 10px 10px;
  display: inline-block;
}

td.active {
  color: #117964;
}

// spinner
.spinner-border {
  position: fixed;
  top: calc(50vh - 1.5rem);
  left: calc(50vw - 1.9rem);
  width: 3rem;
  height: 3rem;
  color: #18bc9c;
}

// text
.text-success {
  color: #117964 !important;
}

// text-align
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

// links
a.card-link,
.nav-link,
.badge.link {
  cursor: pointer;
}

.link {
  cursor: pointer !important;
}

a.link {
  color: #117964 !important;
  // color: #18bc9c !important;
}

td a.link {
  text-decoration: none;
  color: #000 !important;
}

div.link, fa-icon.link {
  color: #117964;
}

// menus
.disabled .dropdown-item {
  color: #ced4da !important;
  pointer-events: none;
}

// btn
.btn:disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

// modal
.modal .btn-close,
.toast .btn-close {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

// cards
.card {
  box-shadow: 6px 11px 41px -28px #a99de7;
}

.card.form-card {
  border-radius: 10px;
  padding: 2rem;
  // padding: 1.3em 1.5em 2em;
  border: 1px solid rgba(77, 5, 5, 0.07);
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.table tbody td:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.table tbody td:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

// forms
.currency {
  text-align: right;
}

.form-control.ng-invalid.ng-touched.ng-dirty {
  border-color: red;
}

.form-select.ng-invalid.ng-touched.ng-dirty {
  border-color: red;
}

fieldset.overflow {
  max-height: 14rem;
  overflow: hidden auto;
}

// Error alert
.invalid-feedback {
  display: block;
}

// ngb-paginator
.pagination .page-link:focus {
  outline: transparent;
  box-shadow: none;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  font-size: 1.5rem;
  line-height: 1.2rem;
}

// ngx-paginator'
.ngx-pagination .current {
  padding: 0.35rem 0.65rem !important;
  background-color: #18bc9c !important; // #0f7864 !important;
}

.ngx-pagination .page-link {
  color: black;
}

.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next {
  font-size: 1.5rem;
}

// ngx-tags
.ngx-input-tags {
  padding: 0.5em !important;
}

label.ngx-tags-header {
  font-weight: normal !important;
}

//border
.b-1 {
  border-radius: 5px !important;
}

.b-2 {
  border-radius: 10px !important;
}

.b-3 {
  border-radius: 15px !important;
}

.b-4 {
  border-radius: 25px !important;
}

.b-5 {
  border-radius: 50px !important;
}

.-top {
  top: -4.5rem;
}

.nav-pills .nav-link {
  background: $gray-200;
  border: 1px solid $gray-500;
}

// table telas pequenas
@media screen and (mim-width: 576px) {
  .min-width-md-1 {
    min-width: 20vw;
  }

  .min-width-md-2 {
    min-width: 30vw;
  }
}

@media screen and (mim-width: 768px) {
  .min-width-md-1 {
    min-width: 15vw;
  }

  .min-width-md-2 {
    min-width: 20vw;
  }
}
